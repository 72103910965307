import {Modal, ModalContent, ModalOverlay, useBreakpointValue, useTheme} from '@chakra-ui/react'
import {ReactNode} from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  size?: string
}

const DarkModalContainer = ({isOpen, onClose, children, size = 'auto'}: Props) => {
  const theme = useTheme()
  const modalSize = useBreakpointValue({base: 'xs', md: 'xl'})

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={modalSize}
      isCentered
      scrollBehavior='inside'
      closeOnOverlayClick={false}
      closeOnEsc={false}>
      <ModalOverlay bg='blackAlpha.0' backdropFilter='blur(15px)' />
      <ModalContent
        p={[7, null, 10]}
        borderRadius={theme.radii.big}
        bg={theme.colors.layers.modalBackground}
        minW={size}>
        {children}
      </ModalContent>
    </Modal>
  )
}

export default DarkModalContainer

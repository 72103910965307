import {SUPPORTED_LANGUAGES} from '../constants/languages'

export class ExamplePostImage {
  constructor(public url: string, public alt?: string) {}
}

export class ExamplePost {
  constructor(
    public nameFr: string,
    public nameEn: string,
    public nameDe: string,
    public slug: string,
    public archived: boolean,
    public draft: boolean,
    public publishedOn: Date,
    public image: ExamplePostImage
  ) {}

  public getName(lang: string): string {
    switch (lang) {
      case SUPPORTED_LANGUAGES.fr:
        return this.nameFr || ''
      case SUPPORTED_LANGUAGES.de:
        return this.nameDe || ''
      case SUPPORTED_LANGUAGES.en:
      default:
        return this.nameEn || ''
    }
  }
}

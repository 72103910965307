import {Box, Button, Heading, Stack, Text, useTheme, VStack} from '@chakra-ui/react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import {faBadgeCheck} from '@fortawesome/pro-solid-svg-icons'
import {getProfileTabURL, ROUTE_PRICING, USER_PROFILE_TABS} from '../../constants/routes'
import {useAuth} from '../../hooks/useAuth'
import {useIsMobile} from '../../hooks/useIsMobile'
import useTranslation from 'next-translate/useTranslation'

const SubscriptionsHome = () => {
  const theme = useTheme()
  const {user} = useAuth().state

  const {t} = useTranslation('subscription')
  const isMobile = useIsMobile()

  return (
    <VStack spacing={7} w='full' align='start'>
      <Heading as='h2' color={theme.colors.text.basic} variant='large'>
        {t('block_subscriptions_home_title')}
      </Heading>
      <VStack
        align={['center', null, 'start']}
        p={[7, null, 10]}
        spacing={7}
        w='full'
        borderRadius={theme.radii.big}
        background={theme.colors.layers.appLevel1}>
        <Stack
          spacing={10}
          direction={isMobile ? 'column' : 'row'}
          align={isMobile ? 'center' : 'start'}>
          <Box>
            <FontAwesomeIcon size='6x' icon={faBadgeCheck} color={theme.colors.brand.success} />
          </Box>
          <VStack align={isMobile ? 'center' : 'start'} spacing={5}>
            <VStack spacing={2} align={isMobile ? 'center' : 'start'}>
              <Heading variant='small' color={theme.colors.text.basic}>
                {t('block_subscriptions_home_header')}
              </Heading>
              <Text color={theme.colors.text.light} align={isMobile ? 'center' : 'start'}>
                {t('block_subscriptions_home_text')}
              </Text>
            </VStack>
            <Link
              href={user ? getProfileTabURL(USER_PROFILE_TABS.subscription) : ROUTE_PRICING}
              passHref>
              <Button variant='primary' w={['full', null, 'auto']}>
                {t('block_subscriptions_home_button')}
              </Button>
            </Link>
          </VStack>
        </Stack>
      </VStack>
    </VStack>
  )
}

export default SubscriptionsHome

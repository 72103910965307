import {Box, Button, Heading, Text, useTheme, VStack} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import hero from '../../public/assets/hero-background-cedille-ai.png'
import Link from 'next/link'
import {useIsMobile} from '../../hooks/useIsMobile'

const HomeHero = () => {
  const {t} = useTranslation('common')
  const theme = useTheme()
  const isMobile = useIsMobile()

  return (
    <Box
      backgroundImage={`url(${hero.src})`}
      filter='drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.2))'
      w='full'
      p={isMobile ? 8 : 16}
      borderRadius='big'
      backgroundSize='cover'>
      <VStack align='start' spacing={10} w={['100%', '100%', '100%', '50%']}>
        <Heading color={theme.colors.text.basic} variant='big'>
          {t('home_header_title')}
        </Heading>
        <Text variant='big' color={theme.colors.text.basic}>
          {t('home_header_subtitle')}
        </Text>
        <Link href='#services-list'>
          <Button variant='primary'>{t('home_header_button')}</Button>
        </Link>
      </VStack>
    </Box>
  )
}
export default HomeHero

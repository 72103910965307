import {Input, InputGroup, InputLeftElement, InputRightElement, useTheme} from '@chakra-ui/react'
import {faEye, faEyeSlash} from '@fortawesome/pro-regular-svg-icons'
import {faLock} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useState} from 'react'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  register: (name: any, options?: any) => any
  first_password?: any
}

const PasswordInput = ({register, first_password}: Props) => {
  const theme = useTheme()
  const {t} = useTranslation('profile')

  const [showInput, setShowInput] = useState<boolean>(false)

  return (
    <InputGroup>
      <InputLeftElement color={theme.colors.brand.white}>
        <FontAwesomeIcon icon={faLock} />
      </InputLeftElement>
      <Input
        variant='filled'
        id={first_password ? 'password_repeat' : 'password'}
        type={showInput ? 'text' : 'password'}
        {...register(
          first_password ? 'password_repeat' : 'password',
          first_password
            ? {
                validate: (value: any) =>
                  value === first_password.current || t('profile_reset_password_not_match'),
              }
            : {
                required: t('profile_input_required'),
                minLength: {value: 8, message: t('profile_input_password_too_short')},
              }
        )}
        placeholder={t('profile_password_placeholder')}
      />
      <InputRightElement _hover={{cursor: 'pointer'}}>
        <FontAwesomeIcon
          color={theme.colors.text.basic}
          icon={showInput ? faEye : faEyeSlash}
          onClick={() => setShowInput(!showInput)}
        />
      </InputRightElement>
    </InputGroup>
  )
}

export default PasswordInput

import {
  Button,
  Divider,
  Heading,
  ModalBody,
  ModalHeader,
  useDisclosure,
  useTheme,
  VStack,
} from '@chakra-ui/react'
import {faChevronRight} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useRouter} from 'next/router'
import {useEffect, useRef, useState} from 'react'
import {useForm} from 'react-hook-form'
import FormControlContainer from '../Molecules/FormControlContainer'
import PasswordInput from '../Molecules/PasswordInput'
import DarkModalContainer from './Components/DarkModalContainer'
import useTranslation from 'next-translate/useTranslation'
import axios from '../../fetchers/axiosAPI'
import {RESET_PASSWORD} from '../../constants/routes'

const ResetPasswordModal = () => {
  const router = useRouter()
  const theme = useTheme()

  const {onOpen, isOpen, onClose} = useDisclosure()

  const {t} = useTranslation('login')

  const [authToken, setAuthToken] = useState<string>()

  const {
    register,
    handleSubmit,
    watch,
    formState: {errors, isSubmitting},
  } = useForm()

  const password_ref = useRef({})
  password_ref.current = watch('password')

  const onSubmit = async (values: any) => {
    const showToast = await import('../../utils/showToast').then(mod => mod.showToast)
    const response = await axios.post(RESET_PASSWORD, {
      authToken: authToken!,
      password: values.password,
    })
    if (response && response.status == 200) {
      showToast(t('login_reset_password_confirm_message'), 'success')
      onClose()
    } else {
      showToast(t('common:generic_error'), 'error')
    }
  }

  useEffect(() => {
    if (router.asPath.includes('#access_token=') && router.asPath.includes('type=recovery')) {
      const authToken = router.asPath.split('&')[0].split('=')[1]
      setAuthToken(authToken)
      onOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, setAuthToken])

  return (
    <DarkModalContainer isOpen={isOpen} onClose={onClose}>
      <ModalHeader p={0}>
        <VStack spacing={7} color={theme.colors.brand.white}>
          <Heading variant='large' textAlign='center' color={theme.colors.brand.white}>
            {t('login_reset_password_modal_title')}
          </Heading>
          <Divider />
        </VStack>
      </ModalHeader>
      <ModalBody mt={5}>
        <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
          <FormControlContainer name='password' errors={errors}>
            <PasswordInput register={register} />
          </FormControlContainer>
          <FormControlContainer name='password_repeat' errors={errors}>
            <PasswordInput register={register} first_password={password_ref} />
          </FormControlContainer>
          <Button
            variant='loginButton'
            type='submit'
            isLoading={isSubmitting}
            rightIcon={<FontAwesomeIcon icon={faChevronRight} size='sm' />}>
            {t('login_reset_password_confirm_button')}
          </Button>
        </form>
      </ModalBody>
    </DarkModalContainer>
  )
}

export default ResetPasswordModal

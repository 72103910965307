import useTranslation from 'next-translate/useTranslation'
import Head from 'next/head'
import {useRouter} from 'next/router'
import {CEDILLE_META_DE, CEDILLE_META_EN, CEDILLE_META_FR} from '../../constants/routes'

interface Props {
  title: string
  description: string
  image?: string
}

const HeadModule = ({title, description, image}: Props) => {
  const router = useRouter()
  const {t} = useTranslation('meta')

  const images: any = {
    fr: CEDILLE_META_FR,
    de: CEDILLE_META_DE,
    en: CEDILLE_META_EN,
  }

  const metaImage = image ?? router.locale ? images[router.locale!] : CEDILLE_META_EN

  return (
    <Head>
      <title>{t(title)}</title>
      <meta property='og:title' content={t(title)} key='og:title' />
      <meta name='description' content={t(description)} key='description' />
      <meta property='og:description' content={t(description)} key='og:description' />
      <meta property='image' content={metaImage} key='image' />
      <meta property='og:image' content={metaImage} key='og:image' />
      <meta property='twitter:title' content={t(title)} key='twitter:title' />
      <meta property='twitter:image' content={metaImage} key='twitter:image' />
      <meta property='twitter:description' content={t(description)} key='twitter:description' />
      <meta name='twitter:card' content='summary_large_image' key='twitter:card' />
      <meta property='og:type' content='website' key='og:type' />
    </Head>
  )
}
export default HeadModule

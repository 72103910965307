import {FormControl, FormErrorMessage, useTheme} from '@chakra-ui/react'
import {ReactNode} from 'react'

interface Props {
  name: string
  children: ReactNode
  errors: any
}

const FormControlContainer = ({name, errors, children}: Props) => {
  const theme = useTheme()
  return (
    <>
      <FormControl isInvalid={errors[name]} mb={5}>
        {children}
        <FormErrorMessage
          color={theme.colors.brand.error}
          fontSize={theme.components.Text.variants.smallBold.fontSize}
          fontWeight={theme.components.Text.variants.smallBold.fontWeight}>
          {errors[name] && errors[name].message}
        </FormErrorMessage>
      </FormControl>
    </>
  )
}
export default FormControlContainer

import {Model, ServiceGroup} from '../dataModels/Service'
import modelImage from '../public/assets/write-freely-tool-cedille-ai.png'
import {ServiceColor, ServiceIcon} from '../utils/sevices'
import {SUPPORTED_LANGUAGES} from './languages'
import {
  API_ROUTE_SKILL_WRITE_FREELY,
  ROUTE_OPEN_MODE_FRENCH,
  ROUTE_OPEN_MODE_GERMAN,
  ROUTE_SERVICE_GROUP_MODELS,
} from './routes'

export const FRENCH_MODEL = new Model(
  API_ROUTE_SKILL_WRITE_FREELY,
  'model:gen_french',
  ROUTE_OPEN_MODE_FRENCH,
  SUPPORTED_LANGUAGES.fr,
  false,
  'model-fr'
)
export const GERMAN_MODEL = new Model(
  API_ROUTE_SKILL_WRITE_FREELY,
  'model:gen_german',
  ROUTE_OPEN_MODE_GERMAN,
  SUPPORTED_LANGUAGES.de,
  false,
  'model-de'
)

export const SUPPORTED_MODELS = new ServiceGroup(
  [FRENCH_MODEL, GERMAN_MODEL],
  ServiceIcon.PEN,
  ServiceColor.GREEN,
  false,
  'service-group:service_write_freely_title',
  'service-group:service_write_freely_description',
  ROUTE_SERVICE_GROUP_MODELS,
  modelImage
)
